<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h2>
          {{ greeting }}, {{ userInfo.name }}
        </h2>
      </v-col>
    </v-row>
    <v-expand-transition>
      <PendingPlanChangeAlert />
    </v-expand-transition>
    <v-expand-transition>
      <ScheduledPlanChangeAlert ref="scheduledPlanChangeAlertRef" />
    </v-expand-transition>
    <v-row>
      <v-col
        cols="12"
        md="4"
      >
        <customer-card
          title="Internet Status"
          :is-loading="isLoading"
          :subtitle="customer.has_internet_access ? 'On' : 'Off'"
          :icon="internetStatusIcon"
          :icon-color="internetStatusColor"
          title-class="title"
          subtitle-class="subtitle"
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <customer-card
          title="Account Credit"
          :is-loading="isLoading"
          :subtitle="'Ksh ' + (customer.credits | formatCurrency)"
          :icon="icons.cash"
          icon-color="success"
          title-class="title"
          subtitle-class="subtitle"
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <customer-card
          title="Debt"
          :is-loading="isLoading"
          :subtitle="'Ksh ' + (customer.debt | formatCurrency)"
          :icon="icons.debt"
          icon-color="warning"
          title-class="title"
          subtitle-class="subtitle"
        />
      </v-col>
    </v-row>
    <v-row v-if="isLoading">
      <v-col
        cols="12"
        md="12"
      >
        <h2>Your Current Plan</h2>
      </v-col>
      <v-col
        v-for="index in 1"
        :key="index"
        md="3"
        class="pa-0"
      >
        <internet-plan-shimmer />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <h2>Your Current Plan</h2>
      </v-col>
      <v-col md="3">
        <customer-plan-card
          :customer-plan="customer.customer_plan"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        class="text-center"
      >
        <h3 class="mb-6 mt-4">
          Account Management Actions
        </h3>
        <v-btn
          large
          color="success"
          class="mx-2 mb-6"
          @click="initiatePayment"
        >
          <v-icon left>
            {{ icons.cash }}
          </v-icon>Make Payment
        </v-btn>
        <v-btn
          large
          color="info"
          class="mx-2 mb-6"
          @click="changeInternetPlan"
        >
          <v-icon left>
            {{ icons.changePlan }}
          </v-icon>Change Internet Plan
        </v-btn>
        <v-btn
          large
          color="secondary"
          class="mx-2 mb-6"
          @click="goToSettings"
        >
          <v-icon left>
            {{ icons.accountCircle }}
          </v-icon>Update Profile
        </v-btn>
        <v-btn
          large
          color="info"
          class="mx-2 mb-6"
          @click="viewPaymentHistory"
        >
          <v-icon left>
            {{ icons.paymentHistory }}
          </v-icon>Payment History
        </v-btn>
      </v-col>
    </v-row>
    <initiate-mpesa-payment-dialog
      :show-dialog="showPaymentDialog"
      :contact-number="phoneNumber"
      @close="showPaymentDialog = false"
    />
  </v-container>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import {
  mdiWeb, mdiCredit, mdiCalendar, mdiWifi, mdiWifiOff, mdiCash, mdiAccountCircle, mdiFinance,
  mdiSwapHorizontal, mdiHistory,
} from '@mdi/js'
import CustomerCard from './CustomerCard.vue'
import InitiateMpesaPaymentDialog from '@/components/dialogs/InitiateMpesaPaymentDialog.vue'
import PendingPlanChangeAlert from '@/components/partials/PendingPlanChangeAlert.vue'
import ScheduledPlanChangeAlert from '@/components/partials/ScheduledPlanChangeAlert.vue'
import CustomerPlanCard from '@/components/partials/CustomerPlanCard.vue'
import InternetPlanShimmer from '@/components/partials/shimmers/InternetPlanShimmer.vue'

export default {
  components: {
    CustomerCard,
    CustomerPlanCard,
    InternetPlanShimmer,
    InitiateMpesaPaymentDialog,
    PendingPlanChangeAlert,
    ScheduledPlanChangeAlert,
  },
  data() {
    return {
      cardMaxWidth: '400',
      isLoading: false,
      showPaymentDialog: false,
      phoneNumber: '',
      customer: {
        customer_plan: {
          internet_plan: {},
        },
      },
      icons: {
        web: mdiWeb,
        credit: mdiCredit,
        calendar: mdiCalendar,
        wifi: mdiWifi,
        wifiOff: mdiWifiOff,
        cash: mdiCash,
        accountCircle: mdiAccountCircle,
        debt: mdiFinance,
        changePlan: mdiSwapHorizontal,
        paymentHistory: mdiHistory,
      },

    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    greeting() {
      const currentHour = new Date().getHours()
      if (currentHour < 12) {
        return 'Good Morning'
      }
      if (currentHour < 18) {
        return 'Good Afternoon'
      }

      return 'Good Evening'
    },
    internetStatusColor() {
      return this.customer.has_internet_access ? 'success' : 'warning'
    },
    internetStatusIcon() {
      return this.customer.has_internet_access ? this.icons.wifi : this.icons.wifiOff
    },
  },
  mounted() {
    this.fetchCustomer()
  },
  methods: {
    fetchCustomer() {
      this.isLoading = true
      axios.get('/customer')
        .then(response => {
          this.customer = response.data.customer
          this.phoneNumber = response.data.phone_number
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    initiatePayment() {
      this.showPaymentDialog = true
    },
    goToSettings() {
      this.$router.push({ name: 'settings' })
    },
    changeInternetPlan() {
      this.$router.push({ name: 'internet-plans', params: { type: 1 } })
    },
    viewPaymentHistory() {
      this.$router.push({ name: 'payments' })
    },
  },
}
</script>

<style scoped>
.title {
  font-size: 1.25rem;
}
.subtitle {
  font-size: 1rem;
}
</style>
