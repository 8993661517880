<template>
  <v-card
    class="internet-plan-card"
  >
    <v-badge
      overlap
      class="badge-right"
      :color="badgeColor"
    >
      <template v-slot:badge>
        {{ badgeStatus }}
      </template>
    </v-badge>
    <v-card-title class="plan-name">
      {{ customerPlan.internet_plan.name }}
    </v-card-title>
    <v-card-text class="plan-details">
      <div
        v-if="!isSpeedInName"
        class="speed"
      >
        {{ customerPlan.internet_plan.download_speed }}{{ speedUnit }}
      </div>
      <div class="price-validity">
        <div class="price">
          Ksh {{ customerPlan.internet_plan.price }}
        </div>
        <div class="validity">
          /{{ formatBillingInterval(customerPlan.internet_plan.validity_unit, customerPlan.internet_plan.validity) }}
        </div>
      </div>
      <div class="expiry-date">
        <span>Expiry Date:</span> <span
          class="bold"
          :class="`${badgeColor}--text`"
        >{{ customerPlan.end_date | formatDate }}</span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import billingIntervalName from '@/mixins/billingIntervalName'
import speedUnit from '@/mixins/speedUnit'
import customerPlanEnum from '@/enums/customerPlanEnum'

export default {
  mixins: [billingIntervalName, speedUnit],
  props: {
    customerPlan: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    isSpeedInName() {
      const speedWithUnit = this.customerPlan.internet_plan.download_speed + this.speedUnit

      return this.customerPlan.internet_plan.name.includes(speedWithUnit)
    },
    badgeStatus() {
      switch (this.customerPlan.status) {
        case customerPlanEnum.EXPIRED:
          return 'Expired'
        case customerPlanEnum.ACTIVE:
          return 'Active'
        case customerPlanEnum.INACTIVE:
          return 'Inactive'
        case customerPlanEnum.CANCELED:
          return 'Canceled'
        default:
          return 'Unknown'
      }
    },
    badgeColor() {
      switch (this.customerPlan.status) {
        case customerPlanEnum.EXPIRED:
          return 'warning'
        case customerPlanEnum.ACTIVE:
          return 'success'
        case customerPlanEnum.INACTIVE:
          return 'warning'
        case customerPlanEnum.CANCELED:
          return 'error'
        default:
          return 'primary'
      }
    },
  },
}
</script>

<style scoped>
.internet-plan-card {
  transition: background-color 0.3s ease;
  border: 2px solid transparent;
  margin-bottom: 2em;
  padding: 1em 0.1em;
}

.plan-name {
  font-weight: bold;
  font-size: 1.5em;
  padding: 10px 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.plan-details {
  line-height: 1.6;
  display: grid;
  gap: 1em;
  text-align: center;
  padding: 20px;
}

.price-validity {
  display: flex;
  flex-direction: column;
  gap: 0.3em; /* Adjust this value as per your requirement */
}

.price {
  font-weight: bold;
}

.choose-plan-button {
  margin-top: 25px;
}
.speed {
  font-weight: bold;
  margin-bottom: 5px;
  text-align: center;
}

.badge-right {
  position: absolute;
  right: 30px;
}

.badge-right::v-deep .v-badge__badge {
  border-radius: 0 !important;
}

.expiry-date {
  font-size: 14px;
  margin-bottom: 5px;
}

.bold {
  font-weight: bold;
}
</style>
