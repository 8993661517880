<template>
  <v-row v-show="Object.keys(InternetPlanUpdateSchedule).length > 0">
    <v-col cols="12">
      <v-alert
        border="left"
        type="info"
      >
        <p>
          Your internet plan is scheduled to be updated to the "{{ newInternetPlanName }}" plan
          <span v-if="updateAfterCurrentPlanEnds">
            after the current plan ends.
          </span>
          <span v-else>
            on {{ scheduledUpdateDate | formatDate }}.
          </span>
        </p>
        <v-btn
          color="error"
          class="ml-2"
          :loading="isCancelingPlanUpdate"
          @click="cancelPlanUpdate"
        >
          <v-icon left>
            {{ icons.cancel }}
          </v-icon>
          Cancel Plan Update
        </v-btn>
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import { mdiCancel } from '@mdi/js'

export default {
  data() {
    return {
      isCancelingPlanUpdate: false,
      InternetPlanUpdateSchedule: {},
      icons: {
        cancel: mdiCancel,
      },
    }
  },
  computed: {
    newInternetPlanName() {
      return this.InternetPlanUpdateSchedule.new_internet_plan?.name ?? 'selected'
    },
    scheduledUpdateDate() {
      return this.InternetPlanUpdateSchedule?.scheduled_update_date
    },
    updateAfterCurrentPlanEnds() {
      return this.InternetPlanUpdateSchedule?.update_after_current_plan_ends
    },
  },
  mounted() {
    this.fetchInternetPlanUpdateSchedule()
  },
  methods: {
    fetchInternetPlanUpdateSchedule() {
      axios
        .get('/customer/internet-plan-update-schedule')
        .then(response => {
          this.InternetPlanUpdateSchedule = response.data
        })
        .catch(error => {
          console.error('Error fetching internet plan update schedule:', error)
          this.$toast.error(error.response.data.message)
        })
    },
    cancelPlanUpdate() {
      this.isCancelingPlanUpdate = true
      axios
        .post('customer/cancel-internet-plan-update-schedule')
        .then(() => {
          this.InternetPlanUpdateSchedule = {}
          this.$toast.success('Plan update cancelled successfully')
        })
        .catch(error => {
          console.error('Error cancelling plan update:', error)
          this.$toast.error(error.response.data.message)
        })
        .finally(() => {
          this.isCancelingPlanUpdate = false
        })
    },
  },
}
</script>
