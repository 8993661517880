<template>
  <v-card
    class="mx-auto white--text"
    :style="{ maxWidth: cardMaxWidth, marginBottom: '20px' }"
  >
    <v-list-item>
      <v-list-item-avatar :color="avatarColor">
        <v-icon
          v-if="!isLoading"
          :color="iconColor"
        >
          {{ icon }}
        </v-icon>
        <v-progress-circular
          v-else
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="headline">
          {{ title }}
        </v-list-item-title>
        <v-list-item-subtitle>{{ isLoading ? 'Loading...' : subtitle }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
export default {
  props: ['isLoading', 'iconColor', 'title', 'subtitle', 'icon', 'avatarColor'],
  data() {
    return {
      cardMaxWidth: '400',
    }
  },
}
</script>
