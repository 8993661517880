const validityEnum = Object.freeze({
  MINUTE: 5,
  HOUR: 0,
  DAY: 1,
  WEEK: 2,
  MONTH: 3,
  YEAR: 4,
})

export default validityEnum
