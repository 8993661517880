<template>
  <v-col>
    <v-card class="pa-4 text-center">
      <div class="skeleton-container">
        <vue-skeleton-loader
          class="mt-1"
          :width="100"
          :height="30"
        />
        <vue-skeleton-loader
          class="mt-6"
          :width="80"
          :height="15"
        />
        <vue-skeleton-loader
          class="mt-2"
          :width="140"
          :height="15"
        />
        <vue-skeleton-loader
          class="mt-6 mb-6"
          :width="180"
          :height="35"
        />
      </div>
    </v-card>
  </v-col>
</template>

<script>
import VueSkeletonLoader from 'skeleton-loader-vue'

export default {
  components: {
    VueSkeletonLoader,
  },

}
</script>

<style>

.skeleton-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
